/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  /**
   * Character counter for the voucher customisation textarea
   * @param val
   */
  function countChar(val) {
    var len = val.value.length;
    var max = ($(val).attr('maxlength') !== undefined) ? $(val).attr('maxlength') : 212;
    if (len > max) {
      val.value = val.value.substring(0, max);
    } else {
      $('#charNum').text(max - len+' / '+max);
    }
    return val.value;
  }


  /**
   * Update the nav cart item count on page load to work around caching
   * @param data
   */
  function rmg_gv_public_ajax(data) {

    //security:
    if (rmg_gv_ajax_object.ajax_nonce !== undefined && rmg_gv_ajax_object.ajax_nonce.length) {
      data.security = rmg_gv_ajax_object.ajax_nonce;
    }

    jQuery.post(rmg_gv_ajax_object.ajax_url, data, function (response) {

      let ajax_response = JSON.parse(response);
      if (typeof ajax_response.cart_count !== 'undefined') {

        let cart_count = ajax_response.cart_count;
        let nav_item = $('li.cart-nav-item');
        if (nav_item.length) {
          if (parseInt(cart_count) > 0) {
            nav_item.removeClass('cart-nav-item-hidden');
            nav_item.find('span.rmg-gv-cart-count').text(cart_count);
          } else {
            nav_item.addClass('cart-nav-item-hidden');
          }
        }

      }

    });
  }//end md_public_ajax

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var RMG_GV = {
    // All pages
    'common': {
      init: function () {

        let ajax_data = {
          'action': 'rmg_gv_public_ajax',
          'rmg_gv_ajax_action': 'update_cart_count'
        };

        rmg_gv_public_ajax(ajax_data);

        var voucher_elems = {
          wrapper: $('div.voucher-fields-wrapper, div.voucher-fields-wrapper-variation'),
          buy_now_btn: $('div.rmg-gv-cart-form-wrap button.single_add_to_cart_button'),
          qty_input: $('div.rmg-gv-cart-form-wrap input.qty'),
          input_name: $('input[id^=recipient_name]'),
          input_message: $('textarea[id^=message]'),
          variation_id: $('input[name="variation_id"]'),
          variation_select: $('select[name^="attribute_"]')
        };
        var rmg_elems = {
          wrapper: $('div.rmg-gv-voucher-image-option'),
          img_select: $('button.rmg-gv-tpl-select'),
          input_name: $('input#rmg-gv-recipient_name'),
          input_message: $('textarea#rmg-gv-message'),
          buy_now_btn: $('button.rmg-gv-buy-now'),
          buy_as_gift_btn: $('button#rmg-gv-buy-as-gift'),
          qty_input: $('input.rmg-gv-cart-qty'),
          customise_screen: $('div.rmg-gv-customise-outer'),
          preview_img: $('img.rmg-gv-voucher-preview-img'),
          preview_recipient: $('p.rmg-gv-recipient'),
          preview_message: $('p.rmg-gv-message'),
          hero_carousel: $('div.rmg-gv-hero-carousel'),
          rmg_gv_variants: $('input[name="rmg-gv-voucher-variation"]')
        };

        if (typeof GLightbox !== 'undefined') {
          let lightbox = GLightbox({
            openEffect: 'fade',
            closeEffect: 'fade',
            slideEffect: 'fade',
            loop: true
          });
        }

        //Product gallery
        if ($('.rmg-gv-gallery').length) {
          $('.rmg-gv-gallery').owlCarousel({
            items: 1
          });
        }


        //Qty onChange
        rmg_elems.qty_input.change(function () {
          var $this = $(this);
          voucher_elems.qty_input.val($this.val());

          //Update the customisation qty field if updating from summary
          if ($this.hasClass('rmg-gv-summary')) {
            $('input.rmg-gv-cart-qty.rmg-gv-customising').val($this.val());
          }
        });


        //Variant init - set Woo cart hidden variant_id input on load
        if (voucher_elems.variation_id.length && rmg_elems.rmg_gv_variants.length) {
          var rmg_selected = $('input[name="rmg-gv-voucher-variation"]:checked').val();
          voucher_elems.variation_select.val(rmg_selected).trigger('change');
        }


        //Variant onChange
        rmg_elems.rmg_gv_variants.change(function(){
          var $this = $(this);
          if ($this.prop('checked', true)) {
            voucher_elems.variation_select.val($this.val()).trigger('change');
          }
        });


        //Set the active voucher image onload
        if ($('button.rmg-gv-tpl-select.rmg-gv-tpl-selected').length) {
          let btn = $('button.rmg-gv-tpl-select.rmg-gv-tpl-selected');
          var img_class = btn.data('img-id');
          voucher_elems.wrapper.find('input.' + img_class).prop('checked', true);
        }


        //Image select click
        rmg_elems.img_select.click(function () {

          var $this = $(this);
          if ($this.hasClass('rmg-gv-tpl-selected')) {
            return false;
          }

          //Get the parent elem so we can update the vals for the summary and preview sections:
          var parent = $('.' + $(this).data('parent'));

          rmg_elems.wrapper.removeClass('rmg-gv-tpl-selected');
          rmg_elems.img_select.removeClass('rmg-gv-tpl-selected');
          parent.addClass('rmg-gv-tpl-selected');

          //Update the image preview:
          rmg_elems.preview_img.attr('src', $this.data('img-src'));

          //Set the hidden img radio input
          var img_class = $this.data('img-id');
          voucher_elems.wrapper.find('input.' + img_class).prop('checked', true);

        });


        //Buy now click
        rmg_elems.buy_now_btn.click(function () {

          //Set the input fields values:
          voucher_elems.input_name.val(rmg_elems.input_name.val());
          voucher_elems.input_message.val(rmg_elems.input_message.val());

          //Trigger Woo add to cart button click
          voucher_elems.buy_now_btn.trigger('click');
        });


        //Buy as gift click
        rmg_elems.buy_as_gift_btn.click(function(){

          rmg_elems.customise_screen.addClass('active');

          var offset = ($('header').length) ? $('header').outerHeight() : 0;
          $("html, body").animate({
            scrollTop: (parseInt($('#customise-voucher').offset().top) - offset)
          }, 500);

        });


        //Recipient input onkeyup
        rmg_elems.input_name.keyup(function(){
          var text = $(this).val();
          if (text.length && text !== '') {
            rmg_elems.preview_recipient.text($(this).val());
          } else {
            rmg_elems.preview_recipient.html('&nbsp;');
          }
        });


        //Message keydown, prevent excessive line-breaks:
        rmg_elems.input_message.keydown(function (e) {
          if (e.which === 13) {
            e.preventDefault();
            return false;
          }
        });

        //Message keyup
        rmg_elems.input_message.keyup(function(){
          let text = countChar(this);
          if (text.length && text !== '') {
            let formattedText = text.replace(/\n/g, '');
            $(this).val(formattedText);
            rmg_elems.preview_message.html(formattedText);
          } else {
            rmg_elems.preview_message.html('&nbsp;');
          }
        });


        //Hero carousel
        if (rmg_elems.hero_carousel.length) {
          rmg_elems.hero_carousel.owlCarousel({
            items: 1,
            dots: false,
            nav: false,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 850,
            loop: true
          });
        }

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var RMG_GV_UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = RMG_GV;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      RMG_GV_UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        RMG_GV_UTIL.fire(classnm);
        RMG_GV_UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      RMG_GV_UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(RMG_GV_UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.















